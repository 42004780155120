import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { rhythm } from '../../utils/typography'
import Image from 'gatsby-image'

class Index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title="Live to GIF Share Tool"
          keywords={[
            `live`,
            `photo`,
            `GIF`,
            `share`,
            `tool`,
            `app`,
            `iOS`,
            `workout`,
            `free`,
            `appstore`,
          ]}
        />
        <h1>Live to GIF Share Tool</h1>
        <Image
          fluid={data.ltgIcon.childImageSharp.fluid}
          alt={`Live to GIF Share Tool`}
          style={{
            display: `block`,
            margin: `${rhythm(1.5)} auto`,
            maxWidth: rhythm(10),
          }}
        />
        <p>
          Quickly and easily share your Live Photos as animated GIFs in
          messages, social networks, and more. Simple and free to use with no
          watermarks or restrictions.
        </p>
        <p>
          Using this app is completely free. Even if premium paid features are
          added down the road, the sharing functionality will remain accessible
          at no cost.
        </p>
        <p>
          To report any issues or submit feature requests, plase send an email
          to <a href="mailto:livetogif@eduardo.dev">livetogif@eduardo.dev</a>.
        </p>
        <a
          href="https://apps.apple.com/us/app/live-photo-to-gif-share-tool/id1510548477?mt=8"
          style={{
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
            overflow: `hidden`,
            background: `url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2011-08-31&kind=iossoftware&bubble=ios_apps) no-repeat`,
            backgroundPosition: `center`,
            width: `135pt`,
            height: `40pt`,
          }}
        ></a>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    ltgIcon: file(absolutePath: { regex: "/ltg-icon.png/" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`
